import React, { Fragment, useState } from "react";
import { Grid, Box, MenuItem, Menu, Avatar, Typography, Divider, Button, AppBar, Toolbar } from "@mui/material";
import { NotificationsNone, SettingsOutlined, Person, PersonAdd } from "@mui/icons-material";
import colors from './../styles/colors';
import Images from './../assets/Images';
import { PrimaryButton } from './../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import useAuth from './../hooks/useAuth';


const Header = () => {
  const { userLogout, userName } = useAuth();
  const navigate = useNavigate()
  // *Anchor Box
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    }
    else {
      setAnchorEl(event.currentTarget);
    }
  };

  const logout = () => {
    userLogout()
    navigate('/login')
  }
  return (
    <AppBar sx={{ mb: 20, bgcolor: colors.white, boxShadow: 0 }}>
      <Toolbar>
        <Grid container py={2} px={3} justifyContent={"space-between"}>
          <Grid item xs={3}></Grid>
          <Grid item xs={9}>
            <Box sx={{
              display: "flex", alignItems: "center",
              cursor: "pointer", float: "right",
            }}>
              <Box
                onClick={(e) => { handleClick(e) }}
                sx={{
                  display: "flex", alignItems: "center", p: 1, borderRadius: "22px",
                  backgroundColor: open ? colors.primary : colors.lightGray,
                  "&:hover": {
                    backgroundColor: colors.primary, cursor: "pointer",
                    ".MuiSvgIcon-root": { color: colors.cloud },
                  },
                }}
              >
                <Avatar alt="Remy Sharp" src={Images.user} sx={{ width: 24, height: 24 }} />
                <SettingsOutlined
                  sx={{ color: open ? colors.lightGray : colors.secondary, fontSize: "26px", ml: 2 }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              width: '350px', px: 2, overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))", mt: 1.5,
              "& .MuiAvatar-root": { width: 60, height: 60, ml: 1, mr: 1, },
              "&:before": {
                content: '""', display: "block", position: "absolute", top: 0,
                right: 14, width: 10, height: 10, bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)", zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Typography sx={{ fontSize: '16px', fontWeight: 500, color: colors.black, p: 1.5 }}>
            User Profile
          </Typography>
          <Box sx={{ display: 'flex', alignItems: "center", cursor: "pointer" }}>
            <Avatar src={Images.userProfile} sx={{ borderRadius: '50px' }} />
            <Box>
              <Typography sx={{ fontSize: '18px', fontWeight: "bold" }}>Name</Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 500, }}>
                user@gmail.com
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 1 }} />
          <MenuItem sx={{ p: 1, borderRadius: "8px" }} >
            <Box sx={{ display: 'flex' }}>
              <Button sx={{ bgcolor: colors.cloud, mr: 1, borderRadius: '10px' }}>
                <Person fontSize="small" />
              </Button>
              <Box >
                <Typography sx={{ fontSize: '1rem', }}> My Profile</Typography>
                <Typography variant="h6"
                  sx={{ fontSize: '0.875rem', }}>
                  Account Settings
                </Typography>
              </Box>
            </Box>
          </MenuItem>
          <Box sx={{ my: 1.5 }}>
            <PrimaryButton
              onClick={logout}
              // data-testId="logout-btn"
              title="logout"
              // onClick={logout}
              fullWidth={true}
            />
          </Box>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default Header