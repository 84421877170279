import { ChevronRightRounded, HomeTwoTone } from '@mui/icons-material';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import colors from './../../styles/colors';

const useStyle = makeStyles({
  linkBtn: {
    color: colors.primary,
    textDecoration: 'none',
    '&:hover': {
      color: colors.textSecondary,
      transition: '0.3s',
    }
  }
})

function BreadCrumbs({ currentPage }) {

  const classes = useStyle()

  return (
    <Box role="presentation" >
      <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightRounded fontSize="small" />}
        sx={{ fontSize: "12px" }}>
        <Link to="/"
          className={classes.linkBtn}
        >
          <HomeTwoTone sx={{ fontSize: "18px", mt: 0.2 }} />
        </Link>
        {currentPage.map((item, index) => (
          <Typography key={index} variant="body1" sx={{ fontSize: "12px" }} color={colors.textSecondary}>{item}</Typography>
        ))}
      </Breadcrumbs>
    </Box>
  )
}

export default BreadCrumbs