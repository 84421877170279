import { get, post, patch, deleted } from '../index'
import OrderRoutes from './Order.Routes';

const OrderServices = {
  getAllOrders: async () => {
    const data = await get(OrderRoutes.getAllOrders)
    return data
  },
  quoteOrder: async (obj) => {
    const data = await post(OrderRoutes.quoteOrders, obj);
    return data
  },
}

export default OrderServices