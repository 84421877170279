import { get, post, patch, deleted } from '../index'
import UsersRoutes from './Users.Routes';

const UsersServices = {
  getAllUsers: async () => {
    const data = await get(UsersRoutes.getAllUsers)
    return data
  }
}

export default UsersServices