import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogTitle, IconButton, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { PrimaryButton, SecondaryButton } from '../Button/Button';
import { InputField } from './../Fields/TextField';

function QuoteDialog({ open, onClose, title, action, loading }) {

  const { register, handleSubmit, formState: { errors } } = useForm();
  return (
    <Dialog
      data-testId="dialog-box"
      maxWidth="xs"
      open={open}
      sx={{ '& .MuiDialog-paper': { width: '80%', height: "auto", borderRadius: 2, py: { xs: 2, md: 4 }, px: { xs: 3, md: 6 } } }}
    >
      <IconButton data-testId="close-dialog" color="primary" onClick={() => onClose()} sx={{ position: 'absolute', right: 13, top: 13 }}>
        <Close />
      </IconButton>
      <DialogTitle sx={{ textAlign: "center", fontSize: '18px', fontWeight: 700 }}>{title}</DialogTitle>
      <Typography component={"form"} onSubmit={handleSubmit((formData) => action(formData))}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} >
            <InputField
              label="Price"
              type="tel"
              error={errors?.price?.message}
              register={register("price", {
                required: 'Please Quote Price.',
              })
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} >
            <InputField
              multiline
              rows={4}
              label="Message"
              type="tel"
              error={errors?.price?.message}
              register={register("message", {
                required: 'Please Type Message Quote.',
              })
              }
            />
          </Grid>
        </Grid>

        <PrimaryButton
          data-testId="yes-btn"
          title="Quote"
          loading={loading}
          type="submit"
          onClick={() => onClose()}
        />
      </Typography>
    </Dialog>
  )
}

export default QuoteDialog