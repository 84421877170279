import React, { Fragment, useEffect, useState } from 'react'
import { Paper, Box, Grid, Typography, tooltipClasses, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, IconButton } from '@mui/material';
import colors from '../../styles/colors';
import { DeleteTwoTone, EditTwoTone, KeyboardArrowLeft, KeyboardArrowRight, PersonAddAltTwoTone, Queue } from '@mui/icons-material'
import styled from '@emotion/styled'
import BreadCrumbs from './../../components/BreadCrumbs/BreadCrumbs';
import { useTheme } from '@emotion/react'
import { Layout } from './../../layout/Layout';
import UsersServices from '../../apis/Users/UsersServices';
import { ErrorToaster, SuccessToaster } from './../../components/Toaster/Toaster';

// Table Pagination ActionsComponent
function TablePaginationActions(props) {

  const theme = useTheme

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };



  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}

// custom tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    textAlign: "center",
    background: "#0D253C",
    "&>.MuiTooltip-arrow": {
      "&:before": {
        color: "#0D253C"
      }
    }
  },
});


const UsersList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [allUsers, getAllUsers] = useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAllUser = async () => {
    try {
      const { data, responseCode, message, status } = await UsersServices.getAllUsers()
      if (status === true && responseCode === 200) {
        getAllUsers(data.result)
      }
    }
    catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    getAllUser()
  }, [])
  return (
    <Layout>
      <Box sx={{
        backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)"
      }} >
        <Grid container alignItems="center" justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Users List</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Users']} />
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{
          '&::-webkit-scrollbar': {
            bgcolor: colors.white,
            height: "8px",
            borderRadius: "10px",
            cursor: "pointer"
          },
          '&::-webkit-scrollbar-thumb': {
            bgcolor: colors.primary,
            borderRadius: "10px",
            cursor: "pointer"
          },
          "&.MuiPaper-root": {
            borderRadius: "12px",
            mt: 2,
            boxShadow: 0,
          }
        }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead >
              <TableRow sx={{ bgcolor: colors.lightGray }}>
                {/* <TableCell sx={{ fontWeight: "bold" }} >#ID</TableCell> */}
                <TableCell sx={{ fontWeight: "bold" }} >Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Phone No.</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Gender</TableCell>
                {/* <TableCell sx={{ fontWeight: "bold" }}>Cnic Number</TableCell> */}
                {/* <TableCell sx={{ fontWeight: "bold" }}>Password</TableCell> */}
                {/* <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0 ? allUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : allUsers).map((row, index) => (
                <TableRow
                  key={index}
                >
                  <TableCell component="th" scope="row" >
                    {row.name}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="initial" noWrap>{row.phone ? row.phone : "no phone"}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="initial" noWrap>{row.email}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="initial" noWrap>{row.gender}</Typography>
                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>
        <Table sx={{ marginTop: "12px" }} >
          <TablePagination
            sx={{ borderBottom: "none", bgcolor: colors.white, borderRadius: "12px" }}
            rowsPerPageOptions={[10, 20, 30]}
            colSpan={12}
            count={allUsers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Table>
      </Box>
    </Layout>
  )
}

export default UsersList