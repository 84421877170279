import React, { Fragment, useEffect, useState } from 'react'
import { Paper, Box, Grid, Typography, tooltipClasses, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, IconButton } from '@mui/material';
import colors from '../../styles/colors';
import { RequestQuote, DeleteTwoTone, EditTwoTone, KeyboardArrowLeft, KeyboardArrowRight, PersonAddAltTwoTone, Queue } from '@mui/icons-material'
import styled from '@emotion/styled'
import BreadCrumbs from './../../components/BreadCrumbs/BreadCrumbs';
import { useTheme } from '@emotion/react'
import { Layout } from './../../layout/Layout';
import OrderServices from '../../apis/Order/OrderServices';
import { ErrorToaster, SuccessToaster } from './../../components/Toaster/Toaster';
import { getTimeDate } from '../../utils/DateFormate';
import QuoteDialog from '../../components/Dialog/QuoteDialog';

// Table Pagination ActionsComponent
function TablePaginationActions(props) {

  const theme = useTheme

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };



  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}

// custom tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    textAlign: "center",
    background: "#0D253C",
    "&>.MuiTooltip-arrow": {
      "&:before": {
        color: "#0D253C"
      }
    }
  },
});

const Order = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderId, setOrderId] = useState("")
  const [rowsData, setRowsData] = useState([]);
  const [quoteDialog, setQuoteDialog] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const allOrders = async () => {
    try {
      const { data } = await OrderServices.getAllOrders()
      if (data === null) return
      setRowsData(data.result)
    }
    catch (error) {
      ErrorToaster(error)
    }
  }

  const quoteOrder = async (quote) => {
    try {
      const obj = {
        order_id: orderId,
        total_amount: quote.price,
        message: quote.message
      }
      const { status, message, responseCode } = await OrderServices.quoteOrder(obj)
      console.log("🚀 ~ file: Order.js:112 ~ quoteOrder ~ data:", message)
      if (status === true && responseCode === 200) {
        SuccessToaster("Offer Quoted")
        allOrders()
      }

    }
    catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    allOrders()
  }, [])

  return (
    <Layout>
      <QuoteDialog open={quoteDialog} onClose={() => setQuoteDialog(!quoteDialog)} title={"Quote Offer"} action={quoteOrder} loading={""} />
      <Box sx={{
        backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)"
      }} >
        <Grid container alignItems="center" justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Orders List</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Orders']} />
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{
          '&::-webkit-scrollbar': {
            bgcolor: colors.white,
            height: "8px",
            borderRadius: "10px",
            cursor: "pointer"
          },
          '&::-webkit-scrollbar-thumb': {
            bgcolor: colors.primary,
            borderRadius: "10px",
            cursor: "pointer"
          },
          "&.MuiPaper-root": {
            borderRadius: "12px",
            mt: 2,
            boxShadow: 0,
          }
        }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead >
              <TableRow sx={{ bgcolor: colors.lightGray }}>
                <TableCell sx={{ fontWeight: "bold" }} >Order Id</TableCell>
                <TableCell sx={{ fontWeight: "bold" }} >Order By</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Phone No.</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Placed On</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
                {/* <TableCell sx={{ fontWeight: "bold" }}>Password</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0 ? rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rowsData).map((row, index) => (
                <TableRow
                  key={index}
                >
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {row.order_id}
                  </TableCell>
                  <TableCell >
                    <Typography variant="body2" color="initial" noWrap>{row.order_by}</Typography>
                  </TableCell>
                  <TableCell>
                    {/* <Typography variant="body2" color="initial" noWrap>{row.email}</Typography> */}
                    <CustomTooltip title={row.order_by_email} arrow>
                      <Typography variant="body2" color="initial" noWrap
                        sx={{ cursor: "default", maxWidth: 170 }}>{row.order_by_email}</Typography>
                    </CustomTooltip>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="initial" noWrap>{row.user_phone ? row.user_phone : "no phone"}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="initial" noWrap>{getTimeDate(row.date_time)}</Typography>
                  </TableCell>
                  <TableCell>
                    <CustomTooltip title={row.status.ar} arrow>
                      <Typography variant="body2" color="initial" noWrap
                        sx={{
                          cursor: "default", maxWidth: 170,
                          color: row.status.name === "Confirmed" ? colors.primary : row.status.name === "Requested" ? colors.danger : colors.primary
                        }}>{row.status.name}</Typography>
                    </CustomTooltip>
                  </TableCell>
                  <TableCell >
                    {row.status.name === "Cancelled" ?
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title="Quote" >
                          <IconButton
                            onClick={() => { setQuoteDialog(!quoteDialog); setOrderId(row?._id) }}
                          >
                            <RequestQuote sx={{ color: colors.secondary, fontSize: "20px" }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      : row.status.name === "Requested" ?
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Tooltip title="Quote" >
                            <IconButton
                              onClick={() => { setQuoteDialog(!quoteDialog); setOrderId(row?._id) }}
                            >
                              <RequestQuote sx={{ color: colors.secondary, fontSize: "20px" }} />
                            </IconButton>
                          </Tooltip>
                        </Box> :
                        <Typography variant="body2" color="initial" noWrap>{row.status.name}</Typography>
                    }

                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>
        <Table sx={{ marginTop: "12px" }} >
          <TablePagination
            sx={{ borderBottom: "none", bgcolor: colors.white, borderRadius: "12px" }}
            rowsPerPageOptions={[10, 20, 30]}
            colSpan={12}
            count={rowsData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Table>
      </Box>
    </Layout>
  )
}

export default Order