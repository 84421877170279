import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { makeStyles } from "@mui/styles";
import PrimaryCard from '../../components/Cards/PrimaryCard';
import { Layout } from '../../layout/Layout'
import colors from './../../styles/colors';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import clsx from "clsx";
import { HeartBroken, CalendarMonth, Person4, MedicalServices } from '@mui/icons-material';
import SecondaryCard from './../../components/Cards/SecondaryCard';
import { faker } from "@faker-js/faker";
import { Divider } from '@mui/material';
import MedicationIcon from '@mui/icons-material/Medication';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles({
  iconStyle: {
    fontSize: "30px",
    borderRadius: "8px",
    zIndex: 99,
    padding: "10px",
    position: "relative",
    top: -15
  },
  smallIconStyle: {
    borderRadius: "8px",
    padding: 8,
    fontSize: "24px",
    marginRight: 12,
  },
  typeIconBgColor: {
    backgroundColor: "#b9b9b9"
  },
  makeIconBgColor: {
    backgroundColor: "#eddaa98f"
  },
})


const Dashboard = () => {
  // Chart  Object
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Total Growth",
        fontSize: "18px",
      },
    },
  };

  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const data = {
    labels,
    datasets: [
      {
        label: "Company",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
        backgroundColor: colors.primary,
      },
      {
        label: "Employess",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
        backgroundColor: colors.secondary,
      },
    ],
  };

  const classes = useStyles()
  return (
    <Layout>
      <Grid container
        justifyContent={"space-between"}
        sx={{
          backgroundColor: colors.lightGray,
          borderRadius: "12px",
          p: 3,
          width: "100%",
        }}
      >
        <Grid item xs={12} md={3.9}>
          <PrimaryCard
            icon={<HeartBroken
              className={classes.iconStyle}
              sx={{ color: colors.primary }}
            />}
            number="20"
            name={"Total Orders"}
            color={colors.white}
            bgColor={colors.primary}
            circle1={"#ffffffbd"}
            circle2={"#ffffff40"}
          />

        </Grid>

        <Grid item xs={12} md={3.9}>
          <PrimaryCard
            icon={<CalendarMonth
              className={classes.iconStyle}
              sx={{ color: colors.mercury }}
            />}
            number="12"
            name={"Quoted Orders"}
            color={colors.cloud}
            bgColor={colors.secondary}
            bgImage={colors.secondaryTopDown}
            circle1={"#ffffff29"}
            circle2={"#0000001f"}
          />
        </Grid>

        <Grid item xs={12} md={3.9}>
          <SecondaryCard icon={<Person4
            className={clsx(classes.smallIconStyle, classes.typeIconBgColor)}
            color={colors.secondaryLight}
          />}
            number={"4"}
            name={"Total Users"}
            nameColor={colors.white}
            color={colors.whiteSmoke}
            bgColor={colors.secondary}
            circle1={"#ffffffbd"}
            circle2={"#ffffff40"}
          />
          <SecondaryCard icon={<MedicalServices
            className={clsx(classes.smallIconStyle, classes.makeIconBgColor)}
            color={colors.artyClickAmber}
          />}
            number={"6"}
            name={"Total Employees"}
            color={colors.white}
            nameColor={colors.white}
            bgColor={colors.eggSour}
            bgImage={colors.accepted}
            circle1={"#07ffa321"}
            circle2={"#4aa58b"}
          />
        </Grid>

        <Grid item xs={12} md={7.9}>
          <Box
            sx={{
              backgroundColor: colors.white,
              my: 2,
              p: 2,
              borderRadius: "12px",
              height: 350
            }}
          >
            <Bar options={options} data={data} />
          </Box>
        </Grid>
        <Grid>
          <Box sx={{
            backgroundColor: colors.white, p: 4, my: 2, borderRadius: "12px", minHeight: "320px"
          }}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}
            >
              <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                Recent Orders
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "bold", color: colors.ebonyClay }}
                >
                  maaz
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ fontSize: "13px", color: colors.ebonyClay, mr: 2 }}
                >
                  Order No: 4001
                </Typography>
                <MedicationIcon
                  sx={{
                    color: colors.primary, backgroundColor: colors.bg2,
                    p: 0.5, borderRadius: "6px", fontSize: "18px",
                  }}
                />
              </Box>
            </Box>
            <Divider
              sx={{
                color: colors.textSecondary, width: "100%", my: 2,
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  sx={{ pr: 1, fontSize: "16px", fontWeight: "bold", color: colors.ebonyClay }}
                >
                  airas
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ fontSize: "13px", color: colors.ebonyClay, mr: 2 }}
                >
                  Order No: 4002
                </Typography>
                <MedicationIcon
                  sx={{
                    color: colors.primary, backgroundColor: colors.bg2,
                    p: 0.5, borderRadius: "6px", fontSize: "18px",
                  }}
                />
              </Box>
            </Box>
            <Divider
              sx={{
                color: colors.textSecondary, width: "100%", my: 2,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Dashboard