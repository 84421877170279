const colors = {
  primary: "#53B1BF",
  secondary: "#285D9A",
  primaryGradient: `linear-gradient(to right, #53b1bf, #349db9, #1a88b2, #1773a8, #285d9a)`,
  primaryTopDown: `linear-gradient(to bottom, #53b1bf, #349db9, #1a88b2, #1773a8, #285d9a)`,
  secondaryGradient: "linear-gradient(to right, #275c98, #225186, #1d4674, #193b62, #153151)",
  secondaryTopDown: "linear-gradient(to bottom, #275c98, #225186, #1d4674, #193b62, #153151)",
  quoted: `linear-gradient(to right, #212835, #2c333f, #373d49, #424953, #4e545e)`,
  accepted: "linear-gradient(to right, #4aa3b7, #319ca8, #189596, #058d82, #0a856d)",
  white: "#ffffff",
  whiteSmoke: "#f6f6f6",
  mercury: "#f5f9fc",
  black: "#222222",
  lightGray: "#e3e8e885",
  danger: "#FF2121",
  ebonyClay: "#252340",
  gunMetal: "#263238",
  success: "#79AA5A",
  cloud: "#C4C4C4",
  pending: "#ff8400",
  skyBlue: "#c3f1f7",
  bg2: "#c8eefa",
}

export default colors