import { Box, Typography } from '@mui/material'
import React, { Fragment } from 'react'

function SecondaryCard({ icon, name, number, color, bgColor, bgImage, circle1, circle2, nameColor }) {
  return (
    <Fragment>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          backgroundColor: bgColor,
          backgroundImage: bgImage,
          borderRadius: "12px",
          p: 2,
          cursor: "pointer",
          mb: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mt: 1, mb: 1 }}>
          {icon}
          <Box>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: color, mr: 1 }}
            >
              {number}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: nameColor }}
            >
              {name}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: -40,
            right: -50,
            zIndex: 1,
            backgroundColor: circle1,
            borderRadius: "50%",
            width: 100,
            height: 100,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 40,
            right: -30,
            backgroundColor: circle2,
            borderRadius: "50%",
            width: 100,
            height: 100,
          }}
        />
      </Box>
    </Fragment>
  )
}

export default SecondaryCard