import Login from './../views/Auth/Login';
import Dashboard from './../views/Dashboard/Dashboard';
import Order from './../views/Orders/Order';
import UsersList from './../views/Users/UsersList';

const AdminRoutes = [
  {
    path: '/login',
    component: <Login />
  },
  {
    path: '/',
    component: <Dashboard />
  },
  {
    path: '/orders',
    component: <Order />
  },
  {
    path: '/users',
    component: <UsersList />
  },
];

export default AdminRoutes